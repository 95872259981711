import React, { Fragment, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import SectionContainer from '../../../../components/dashboard/assessments/create-assessment/section-types/SectionContainer';
import Button from '../../../../components/sub-component/Button';
import Input from '../../../../components/sub-component/Input';
// import ReactPlayer from 'react-player';

import { ReactComponent as TrashIcon } from '../../../../assets/icons/Views/dashboard/assessments/trash.svg';
import { ReactComponent as Trash_2Icon } from '../../../../assets/icons/Views/dashboard/assessments/trash-2.svg';
import { ReactComponent as MoreIcon } from '../../../../assets/icons/Views/dashboard/assessments/more-vertical.svg';
import { ReactComponent as MoveIcon } from '../../../../assets/icons/Views/dashboard/assessments/move.svg';
import { ReactComponent as MaximizeIcon } from '../../../../assets/icons/Views/dashboard/assessments/maximize-2.svg';
import { ReactComponent as RotateIcon } from '../../../../assets/icons/Views/dashboard/assessments/rotate-cw.svg';
import { ReactComponent as VideoIcon } from '../../../../assets/icons/Views/dashboard/assessments/film.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/Views/dashboard/create-assessment/link.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/icons/Views/dashboard/create-assessment/upload.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/Views/dashboard/assessments/alert-circle.svg';
import { ReactComponent as TickIcon } from '../../../../assets/icons/Views/dashboard/assessments/tick.svg';
import { useDispatch, useSelector } from 'react-redux';
import { createResource, deleteResource, getResource, resetResourceState } from '../../../../redux/thunks/Resource';
import CustomModal from '../../../../components/common/Modal';
import { WelcomeVideoValidator } from './AssessmentCreationValidators';
import { useFocusWithin } from '@react-aria/interactions';
import { useMediaQuery } from '@mui/material';


export default function WelcomeVideo(props) {
	const isOnTabletScreen = useMediaQuery('(max-width:1439px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const { assessmentSpecsDetails, isSelected, openSectionType, setOpenSectionType } = props;
	const [isOpenWVMenu, setIsOpenWVMenu] = useState(false);
	const [isSectionDropdownOpen, setIsSectionDropdownOpen] = useState(false);
	const [selectedSectionType, setSelectedSectionType] = useState(null);
	const [toDragIndex, setToDragIndex] = useState(-1);
	const [fromDragIndex, setFromDragIndex] = useState(-1);
	const [sectionToDelete, setSectionToDelete] = useState({
		index: -1,
		showModal: false,
		showDeleteModal: false
	});


	const _SectionTypes = [
		{ id: 'file-upload', icon: <UploadIcon style={{ width: '20px', height: '20px' }} />, title: 'File Upload' },
		{ id: 'add-link', icon: <LinkIcon style={{ width: '20px', height: '20px' }} />, title: 'Add Link' },
	];


	// const [videoFile, setVideoFile] = useState(null);
	const [welcomeVideo, setWelcomeVideo] = useState({
		type: null,
		src: null
	});
	const [isVideoUploading, setIsVideoUploading] = useState(false);
	const [isReplace, setIsReplace] = useState(false);

	const [activeSection, setActiveSection] = useState({ type: 'welcome_video', section: 'null' });
	const [validationAfterFirstRender, setValidationAfterFirstRender] = useState(false);

	// const [canUploadVideo, setCanUploadVideo] = useState(true);
	const [validationStatus, setValidationStatus] = useState(null);
	// const [isUploadPending, setIsUploadPending] = useState(false);
	// const [shouldDeleteOnCofirm, setShouldDeleteOnConfirm] = useState(false);

	/**Redux */
	const assessSpec = useSelector((state) => state.assessSpecReducer);

	const dispatch = useDispatch();

	const resources = useSelector((state) => state.resourceReducer);

	const handleUploadVideo = async (file) => {
		// if (welcomeVideo?.src !== assessSpec?.curr_assessment_spec?.welcome_video?.video_id) {
		// 	if(assessSpec?.curr_assessment_spec?.welcome_video?.video_id)
		// 	await dispatch(deleteResource({ id: assessSpec?.curr_assessment_spec?.welcome_video?.video_id }));
		// }

		const formData = new FormData();
		formData.append('file', file, file.name);
		setIsVideoUploading(true);
		const result = await dispatch(createResource({ data: formData, signal: null }));
		if (result?.meta?.requestStatus === 'fulfilled') {
			setWelcomeVideo({
				type: 'id',
				src: result?.payload?.id || null
			});
			setIsVideoUploading(false);
		}

		props.onConfirm({
			...assessmentSpecsDetails, welcome_video: {
				type: 'id',
				src: result?.payload?.id || null
			}
		});
		setValidationStatus(null);
	};

	const getSubtitle = (sectionDetails) => {
		if (welcomeVideo.src) {
			return sectionDetails?.type === 'id' ? 'Welcome video is uploaded as a file' : 'Link is added for welcome video';
		} else {
			return sectionDetails?.type === 'id' ? 'No video uploaded' : 'No link attached';
		}
	};

	const handleDeleteVideo = async (action) => {
		if (resources.data?.id) {
			if (assessSpec?.curr_assessment_spec?.welcome_video?.video_id)
				await dispatch(deleteResource({ id: assessSpec?.curr_assessment_spec?.welcome_video?.video_id }));
			dispatch(resetResourceState());
		}
		setWelcomeVideo({ ...welcomeVideo, src: null });
		setSectionToDelete({ ...sectionToDelete, showDeleteModal: false });
		props.onConfirm({ ...assessmentSpecsDetails, welcome_video: { ...welcomeVideo, src: null } });
	};

	const handleRemoveSection = async () => {
		if (welcomeVideo.type === 'id') {
			if (welcomeVideo?.src && welcomeVideo?.src !== '') {
				await dispatch(deleteResource({ id: welcomeVideo?.src }));
				await dispatch(resetResourceState());
			}
		}
		props.onConfirm({ ...assessmentSpecsDetails, welcome_video: { type: null, src: null } });
		setWelcomeVideo({ type: null, src: null });
		setSectionToDelete({ index: -1, showModal: false });
	};

	useEffect(() => {

		if (assessmentSpecsDetails.welcome_video?.type === 'id' && assessmentSpecsDetails.welcome_video.src) {
			dispatch(getResource({ id: assessmentSpecsDetails.welcome_video.src }));
		}

		if (assessmentSpecsDetails.welcome_video?.type === 'id') {
			setWelcomeVideo({
				type: assessmentSpecsDetails?.welcome_video?.type || null,
				src: assessmentSpecsDetails?.welcome_video?.src || null
			});
		}
		else if (assessmentSpecsDetails.welcome_video?.type === 'url') {
			setWelcomeVideo({
				type: assessmentSpecsDetails?.welcome_video?.type || null,
				src: assessmentSpecsDetails?.welcome_video?.video_url || null
			});
		}
		setValidationAfterFirstRender(true);


		return () => {
			dispatch(resetResourceState());
			setWelcomeVideo({ type: null, src: null });
		};

	}, []);

	useMemo(() => {
		if (assessSpec?.curr_assessment_spec?.welcome_video) {
			const validate = WelcomeVideoValidator(assessSpec?.curr_assessment_spec?.welcome_video);
			setValidationStatus({ ...validate });
		}
	}, []);

	useMemo(() => {
		if (welcomeVideo?.type === 'id' && welcomeVideo?.src && !resources.data?.type.includes('video')) {
			dispatch(getResource({ id: welcomeVideo.src }));
		}
	}, [resources.data?.url, welcomeVideo.src]);

	useMemo(() => {
		if (props.checkValidation) {
			// console.log('validation');
			const validate = WelcomeVideoValidator(welcomeVideo);
			setValidationStatus({ ...validate });
		}
	}, [props.checkValidation]);



	useEffect(() => {
		// console.log(validationStatus, 'validationStatus');
	}, [validationStatus]);



	const EmptySection = () => {
		return (
			<div className={`default-section-div pointer`} style={{ borderColor: '#96999C' }} onClick={() => { if (isOnMobileScreen) { setOpenSectionType({ type: 'welcome_video', isOpen: true }); } }}>
				<div className='d-flex align-items-center' style={{ gap: '8px' }} onClick={() => { setIsSectionDropdownOpen(true); }}>
					<span className='body-2  dark-50'>{'Add Section'}</span>
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M5 7.5L10 12.5L15 7.5" stroke="#808191" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
					</svg>
				</div>
				<div className='default-section-options'>
					{
						isSectionDropdownOpen &&
						_SectionTypes.map((sectionType, index) => {
							return (
								<div onClick={() => { setSelectedSectionType(sectionType.id); addSections(sectionType.id); setIsSectionDropdownOpen(false); }} key={index} className='default-section-option' style={{ borderRadius: index < (_SectionTypes.length - 1) ? '24px 24px 0px 0px' : '0px 0px 24px 24px' }}>
									<span className='body-2 dark-100'>{sectionType.title}</span>
								</div>
							);
						})
					}
				</div>
			</div>
		);
	};


	const MinimizeViewOfSection = (props) => {
		const { onRemoveSection, onSelect, sectionDetails, draggable, onDragStart, errors } = props;

		return (
			<div style={{ position: 'relative' }} className={(!validationStatus?.isValidate) ? 'minize-view-of-section-container minize-view-of-section-container-error pointer' : 'minize-view-of-section-container pointer'} draggable={draggable} onDragStart={onDragStart}>
				<div className={`d-flex align-items-center position-absolute`} style={{ right: '16px', top: '12px' }} >
					<TrashIcon className="pointer" style={{ width: '24px', height: '24px' }} onClick={() => { setSectionToDelete({ ...sectionToDelete, showModal: true }); }} />
				</div>
				<div onClick={onSelect} className="w-100 h-100 d-flex flex-column align-items-start justify-content-between" style={{ gap: '6px' }}>
					<div className='d-flex align-items-center justify-content-between w-100'>
						{/* {
							(!validationStatus?.isValidate) &&
							<div className='d-flex align-items-center justify-content-start' style={{ marginRight: '10px' }}>
								<WarningIcon style={{ width: '28px', height: '28px' }} />
							</div>
						} */}
						<div className='w-100 d-flex align-items-center justify-content-start'>
							{sectionDetails?.type === 'id' ? <UploadIcon style={{ width: '20px', height: '20px', stroke: '#121216' }} /> : <LinkIcon style={{ width: '20px', height: '20px', stroke: '#121216' }} />}
							<span className='body-2 dark-100' style={{ marginLeft: '10px' }}>{sectionDetails?.type === 'id' ? 'File Upload' : 'Add Link'}</span>
						</div>

					</div>
					<div>
						<span className='body-3 dark-50'>{getSubtitle(sectionDetails)}</span>
					</div>
				</div>
			</div>
		);
	};

	const addSections = (selectedSection) => {
		let WV = null;
		if (selectedSectionType === 'file-upload' || selectedSection === 'file-upload') {
			WV = { type: 'id', src: '' };
		}
		else if (selectedSectionType === 'add-link' || selectedSection === 'add-link') {
			WV = { type: 'url', src: '' };
		}
		setWelcomeVideo(WV);

		props.getActiveSection(WV);
		setActiveSection(WV);
		props.onConfirm({ ...assessmentSpecsDetails, welcome_video: WV });
		setValidationStatus(null);
	};


	const VideoInput = useMemo(() => {
		return (
			<input
				type="file"
				// disabled={!canUploadVideo}
				accept='video/mp4, video/ogg, video/webm'
				onChange={(e) => {
					// setVideoFile(e.target.files[0]);
					if ((e.target.files[0].size / 1024) / 1024 <= 25) {
						setWelcomeVideo({ type: 'id', src: '' });
						setIsReplace(true);
						handleUploadVideo(e.target.files[0]);
					}
					else {
						setValidationStatus({ isValidate: false, message: 'Upload a video with size 25MB!', required: 'src' });
					}
				}}
			/>
		);
	}, []);

	useMemo(() => {
		props.getCurrentWVComponentData({ ...welcomeVideo });
	}, [welcomeVideo?.type]);

	useMemo(() => {
		if (welcomeVideo?.type === 'id' || !welcomeVideo?.src) {
			props.getCurrentWVComponentData({ ...welcomeVideo });
		}
	}, [welcomeVideo?.src]);

	const updateOnBlur = () => {
		// console.log('blur');
		props.getCurrentWVComponentData({ ...welcomeVideo });
	};


	// const { focusWithinProps } = useFocusWithin({
	// 	onFocusWithin: e => { },
	// 	onBlurWithin: e => {
	// 		const validate = WelcomeVideoValidator(welcomeVideo);
	// 		setValidationStatus(validate);
	// 		setisMinimized(true);
	// 	}
	// });

	// useEffect(() => {
	// 	if (isReplace) {
	// 		dispatch(deleteResource({ id: assessSpec?.curr_assessment_spec?.welcome_video?.video_id }));
	// 		setIsReplace(false);
	// 	}
	// }, [isReplace]);

	useEffect(() => {
		if (selectedSectionType && isOnTabletScreen) {
			addSections();
		}
	}, [selectedSectionType, isOnTabletScreen]);

	useEffect(() => {
		if (validationAfterFirstRender) {
			if (props?.activeSection?.type && props?.activeSection?.section !== null) {
				setActiveSection(props?.activeSection);
			}

			if ((props?.activeSection?.type === 'welcome_video' && props?.activeSection?.section !== null)) {
				// console.log('Open');
			}
			else {
				// console.log('close');
				const validate = WelcomeVideoValidator(welcomeVideo);
				setValidationStatus(validate);
			}
		}
	}, [props?.activeSection, validationAfterFirstRender]);



	return (
		<>
			<CustomModal
				open={sectionToDelete.showModal}
				varrient='alert-warning-modal'
				title='Delete Section?'
				description={'Are you sure you want to delete this section'}
				dangerAlert={true}
				handleCloseModal={() => { setSectionToDelete({ ...sectionToDelete, showModal: false }); }}
				confirmBtnTitle={'Yes, Delete'}
				btnWidth='max-content'
				onConfirm={() => { handleRemoveSection(); }}
			/>

			<CustomModal
				open={sectionToDelete.showDeleteModal}
				varrient='alert-warning-modal'
				title='Delete Video?'
				description={'Are you sure you want to delete this video?'}
				dangerAlert={true}
				handleCloseModal={() => { setSectionToDelete({ ...sectionToDelete, showDeleteModal: false }); }}
				confirmBtnTitle={'Yes, Delete'}
				btnWidth='max-content'
				onConfirm={() => { handleDeleteVideo('delete-video'); }}
			/>
			{
				!isOnMobileScreen ?
					<div className='create-assessment-secttion' ref={props.ref}>
						<div className='create-assessment-secttion-container'>
							<div className='create-assessment-secttion-container-header position-relative'>
								<div className='w-100 h-100 d-flex flex-column align-items-start' style={{ justifyContent: 'space-between' }}>
									<span className='subtitle-1 dark-100'>{'Welcome Video'}</span>
									<span className='body-2 dark-50' style={{ marginTop: '8px', textAlign: 'left', lineHeight: '20px' }}>{"You can include a pre-recorded video to welcome your candidates to your assessment. By default, we'll display your video at the beginning of the assessment."}</span>

								</div>
								<div className='h-100 d-flex align-items-start justify-content-end' style={{ width: '30px' }}>
									<TrashIcon className="pointer" style={{ width: '24px', height: '24px' }} onClick={() => {
										// handleDeleteVideo();
										props.onDelete();
									}} />
								</div>
							</div>
							<div className='add-section-container'>
								<div style={{ width: '100%' }}
									// tabIndex={'-1'} {...focusWithinProps}
									onDragOver={() => { setToDragIndex(0); }}>
									{
										welcomeVideo.type !== null
											?
											<>
												{
													(activeSection?.type === 'welcome_video' && activeSection?.section !== null)
														?
														<SectionContainer
															title={welcomeVideo.type === 'id' ? 'File Upload' : 'Video Link'}
															description={welcomeVideo.type === 'id' ? 'Confirm and choose the file' : 'Add a YouTube link to link your welcome video here.'}
															enableDuration={false}
															onRemoveSection={() => { setSectionToDelete({ ...sectionToDelete, showModal: true }); }}
															onConfirm={async () => {
																const validate = WelcomeVideoValidator(welcomeVideo);
																if (validate?.isValidate) {
																	setValidationStatus(null);
																	if (welcomeVideo.type === 'id') {
																		if (assessSpec?.curr_assessment_spec?.welcome_video?.video_id && assessSpec?.curr_assessment_spec?.welcome_video?.video_id !== welcomeVideo.src) {
																			await dispatch(deleteResource({ id: assessSpec?.curr_assessment_spec?.welcome_video?.video_id }));
																		}
																		props.onConfirm({ ...assessmentSpecsDetails, welcome_video: welcomeVideo });
																	}
																	else {
																		props.onConfirm({ ...assessmentSpecsDetails, welcome_video: welcomeVideo });
																	}
																}
																else {
																	setValidationStatus(validate);
																}

															}}
															onCancel={() => {
																const validate = WelcomeVideoValidator(welcomeVideo);
																setValidationStatus(validate);
															}}


															error={validationStatus?.isValidate === false}
															processing={props.processing}
															disabled={props.processing || (welcomeVideo.type === 'url' ? (!welcomeVideo.type || !welcomeVideo.src) : resources.data?.url === undefined ? true : false)}
														// hasError={errorQuestionIndexes.includes(questionIndex)}
														>
															{(!validationStatus?.isValidate && validationStatus?.required === 'src') && (
																<div className='error-box mt-2 w-100 mb-2'>
																	<WarningIcon style={{ width: '24px', height: '24px', marginRight: '12px' }} />
																	<span className='body-3 text-danger'>
																		{validationStatus?.message}
																	</span>
																</div>
															)}
															{
																welcomeVideo.type === 'id'
																	?
																	<div className='w-100 d-flex flex-column align-items-start mt-2' style={{ padding: '24px', background: 'white', borderRadius: '24px' }}>
																		<span className='body-2 dark-50' style={{ paddingLeft: '24px' }}>Upload your video <span className='danger-text'>*</span></span>
																		{
																			// resources?.data?.url
																			welcomeVideo?.src
																				?
																				<div className='video-preview-outer-container'>
																					<div className='video-preview-container'>

																						<div style={{ position: 'relative', width: '100%', height: '100%' }}>

																							<video
																								style={{ width: '100%', height: '100%' }}
																								src={resources.data?.url}
																								controls={true}
																								muted={true}
																								disablePictureInPicture={true}
																								controlsList='nodownload noremoteplayback noplaybackrate'

																							/>
																						</div>

																					</div>
																					<div className='d-flex flex align-items-center' style={{ marginLeft: '5px', width: '50px' }}>
																						<div className='WV-reupload-video pointer'>
																							<RotateIcon style={{ width: '17px', height: '17px' }} />
																							{VideoInput}
																						</div>
																						<div className='d-flex flex align-items-center'>
																							<TrashIcon
																								onClick={() => { setSectionToDelete({ ...sectionToDelete, showDeleteModal: true }); }}
																								className="pointer"
																								style={{ marginLeft: '17px', width: '20px', height: '20px' }} />
																						</div>

																					</div>

																				</div>
																				:
																				<div className='WV-upload-video pointer mt-2'>

																					{
																						!resources.processing || !isVideoUploading ? (
																							<>
																								{VideoInput}
																								<span className={`body-3 black-400`} style={(!validationStatus?.isValidate && validationStatus?.required === 'src') ? { fontWeight: '600', color: '#FC4848', borderColor: '#FC4848' } : { fontWeight: '600' }}> Drop files here or click to upload</span>
																							</>
																						) : (

																							<div
																								style={{
																									position: 'absolute',
																									fontWeight: '600',
																									left: '0',
																									background: '#121216',
																									height: '100%',
																									width: `${!isReplace ? '100' : resources.upload_percentage}%`,
																									transition: '1s',
																								}}
																							>
																								<span
																									className='body-3'
																									style={{
																										color: '#FFF',
																										fontWeight: '600',
																										position: 'absolute',
																										top: '50%',
																										left: '50%',
																										translate: '-50% -50%'
																									}}>{`${!isReplace ? '100' : resources.upload_percentage}%`}</span>

																							</div>
																						)
																					}
																				</div>
																		}

																	</div>
																	:
																	<div className='w-100 d-flex flex-column align-items-start mt-2' style={{ padding: '24px', background: 'white', borderRadius: '24px' }}>
																		<span className='body-2 dark-50 mb-2' style={{ paddingLeft: '24px' }}>Insert Link <span className='danger-text'>*</span></span>
																		<Input
																			varrient={'primary'}
																			placeholder="Paste link here"
																			value={welcomeVideo.type === 'url' ? welcomeVideo.src : ''}
																			// style={{ }}
																			name="welcome-video"
																			onChange={(e) => {
																				const validate = WelcomeVideoValidator({ type: 'url', src: e.target.value });
																				if (validate.isValidate) {
																					setValidationStatus(validate);
																				}
																				setWelcomeVideo({ type: 'url', src: e.target.value });
																			}}
																			onBlur={() => { updateOnBlur(); }}
																			className={`${props?.errors?.welcome_video?.src
																				? 'primary-input-error'
																				: ''}`}
																		// onBlur={(e) => {
																		// 	props.onConfirm({
																		// 		...assessmentSpecsDetails, welcome_video: {
																		// 			...welcomeVideo,
																		// 			src: e.target.value || null
																		// 		}
																		// 	});
																		// }}
																		/>
																	</div>

															}
															{/* {
																videoError &&
																<div className='w-100 d-flex justify-content-end' style={{ marginTop: '10px' }}>
																	<span className='body-3' style={{ color: 'red' }}>{videoError}</span>
																</div>
															} */}
														</SectionContainer>
														:
														<MinimizeViewOfSection
															sectionDetails={welcomeVideo}
															onRemoveSection={() => { }}
															onSelect={() => { props.getActiveSection(welcomeVideo); }}
															errors={props.errors}
														/>
												}
											</>

											:
											<EmptySection />
									}
								</div>

							</div>
						</div>

						{/* <div className='create-assessment-secttion-options-container'>
							<div className='section-type-container'>
								{
									_SectionTypes.map((sectionType, index) => {
										const draggable = !isOnTabletScreen && !assessmentSpecsDetails?.sections?.find(section => section?.type === sectionType?.id);
										const type = welcomeVideo?.type === 'id' ? 'file-upload' : 'add-link';
										const btnStatus = (validationStatus && (welcomeVideo.type || welcomeVideo.src)) ? type === sectionType.id ? validationStatus?.isValidate ? { success: true } : { success: false } : null : null;
										return (
											<Fragment key={index}>
												{
													<Button
														onClick={
															() => {
																if (isOnTabletScreen) {
																	setSelectedSectionType(sectionType.id);
																}
															}
														}
														// disabled={(welcomeVideo?.type === 'id' && sectionType.id === 'file-upload') || (welcomeVideo?.type === 'url' && sectionType.id === 'add-link')}
														draggable={draggable}
														onDragEnd={async () => { addSections(); }}
														onDragStart={() => { setSelectedSectionType(sectionType.id); setFromDragIndex(-1); }}
														className={`draggable body-2 dark-50	${btnStatus ? (btnStatus.success === false) ? ' in-section-btn-danger ' : ' in-section-btn-success ' : ''}`}
														key={index} btn='in-section-btn'

														preIcon={btnStatus ? btnStatus?.success ? <TickIcon style={{ width: '20px', height: '20px' }} /> : <WarningIcon style={{ width: '20px', height: '20px' }} /> : sectionType.icon}
														title={sectionType.title}
														style={{ width: '100%', maxWidth: '278px', marginTop: index === 0 ? '0px' : '16px', cursor: `${draggable ? 'grab' : 'not-allowed'}` }} />
												}
											</Fragment>
										);
									})
								}
							</div>
						</div> */}
					</div>
					:
					<div className='create-assessment-secttion' ref={props.ref}>
						{
							!openSectionType.isOpen ?
								<div className='create-assessment-secttion-container'>
									<div className='create-assessment-secttion-container-header position-relative'>
										<div className='w-100 h-100 d-flex flex-column align-items-start' style={{ justifyContent: 'space-between' }}>
											<span className='headline-small semibold black-700'>{'Welcome Video'}</span>
											<span className='body-2 black-500' style={{ marginTop: '8px', textAlign: 'left', lineHeight: '20px' }}>{"You can include a pre-recorded video to welcome your candidates to your assessment. By default, we'll display your video at the beginning of the assessment."}</span>
										</div>
										<div className='h-100 d-flex align-items-start justify-content-end' style={{ width: '30px' }}>
											<TrashIcon className="pointer" style={{ width: '24px', height: '24px' }} onClick={() => {
												// handleDeleteVideo();
												props.onDelete();
											}} />
										</div>
										{/* <div className='h-100 d-flex align-items-start justify-content-end' style={{ width: '30px' }}>
											<MoreIcon className="pointer" style={{ width: '20px', height: '20px' }} onClick={() => setIsOpenWVMenu(!isOpenWVMenu)} />
										</div>
										{
											isOpenWVMenu &&
											<div className='d-flex flex-column align-items-start' style={{ width: '240px', height: 'auto', padding: '0px 12px', background: '#FFF', border: '1px solid #DEDAF2', borderRadius: '3px', zIndex: '1', position: 'absolute', top: '35px', right: '25px' }}>
												<span className='w-100 body-2 black-700 pointer text-start' style={{ padding: '16px 0px', borderBottom: '1px solid #DEDAF2' }} onClick={() => { setOpenSectionType({ type: 'welcome_video', isOpen: true }); setIsOpenWVMenu(false); }}>Change Welcome Video Type</span>
												<span className='w-100 body-2 black-700 pointer text-start' style={{ padding: '16px 0px' }} onClick={() => { props.onDelete(); }}>Delete Welcome Video</span>
											</div>
										} */}
									</div>
									<div className='add-section-container pointer'>
										<div style={{ width: '100%' }}
										// tabIndex={'-1'} {...focusWithinProps}
										// onDragOver={() => { setToDragIndex(0); }}
										>
											{
												welcomeVideo.type !== null
													?
													<>
														{
															(activeSection?.type === 'welcome_video' && activeSection?.section !== null)
																?
																<SectionContainer
																	title={welcomeVideo.type === 'id' ? 'File Upload' : 'Video Link'}
																	description={welcomeVideo.type === 'id' ? 'Confirm and choose the file' : 'Add a YouTube link to link your welcome video here.'}
																	enableDuration={false}
																	onRemoveSection={() => { setSectionToDelete({ ...sectionToDelete, showModal: true }); }}
																	onConfirm={async () => {
																		const validate = WelcomeVideoValidator(welcomeVideo);
																		if (validate?.isValidate) {
																			setValidationStatus(null);
																			if (welcomeVideo.type === 'id') {
																				if (assessSpec?.curr_assessment_spec?.welcome_video?.video_id && assessSpec?.curr_assessment_spec?.welcome_video?.video_id !== welcomeVideo.src) {
																					await dispatch(deleteResource({ id: assessSpec?.curr_assessment_spec?.welcome_video?.video_id }));
																				}
																				props.onConfirm({ ...assessmentSpecsDetails, welcome_video: welcomeVideo });
																			}
																			else {
																				props.onConfirm({ ...assessmentSpecsDetails, welcome_video: welcomeVideo });
																			}
																		}
																		else {
																			setValidationStatus(validate);
																		}

																	}}
																	onCancel={() => {
																		const validate = WelcomeVideoValidator(welcomeVideo);
																		setValidationStatus(validate);
																	}}


																	error={validationStatus?.isValidate === false}
																	processing={props.processing}
																	disabled={props.processing || (welcomeVideo.type === 'url' ? (!welcomeVideo.type || !welcomeVideo.src) : resources.data?.url === undefined ? true : false)}
																>
																	{(!validationStatus?.isValidate && validationStatus?.required === 'src') && (
																		<div className='error-box mt-2'>
																			<span className='body-3 text-danger'>
																				{validationStatus?.message}
																			</span>
																		</div>
																	)}
																	{
																		welcomeVideo.type === 'id'
																			?
																			<div style={{ marginTop: '24px' }} className='w-100 d-flex flex-column align-items-start'>
																				<span className='body-2 black-700'>Upload your video <span className='danger-text'>*</span></span>
																				{
																					welcomeVideo?.src
																						?
																						<div className='video-preview-outer-container'>
																							<div className='video-preview-container'>

																								<div style={{ position: 'relative', width: '100%', height: '100%' }}>

																									<video
																										style={{ width: '100%', height: '100%' }}
																										src={resources.data?.url}
																										controls={true}
																										muted={true}
																										disablePictureInPicture={true}
																										controlsList='nodownload noremoteplayback noplaybackrate'

																									/>
																								</div>
																							</div>
																							<div className='d-flex flex align-items-center' style={{ marginLeft: '5px', width: '50px' }}>
																								<div className='WV-reupload-video pointer'>
																									<RotateIcon style={{ width: '17px', height: '17px' }} />
																									{VideoInput}
																								</div>
																								<div className='d-flex flex align-items-center'>
																									<TrashIcon
																										onClick={() => { setSectionToDelete({ ...sectionToDelete, showDeleteModal: true }); }}
																										className="pointer"
																										style={{ marginLeft: '17px', width: '20px', height: '20px' }} />
																								</div>
																							</div>
																						</div>
																						:
																						<div className='WV-upload-video pointer mt-2'>
																							{
																								!resources.processing || !isVideoUploading ? (
																									<>
																										{VideoInput}
																										<span className={`body-3 black-400`} style={(!validationStatus?.isValidate && validationStatus?.required === 'src') ? { fontWeight: '600', color: '#FC4848', borderColor: '#FC4848' } : { fontWeight: '600' }}> Drop files here or click to upload</span>
																									</>
																								) : (
																									<div
																										style={{
																											position: 'absolute',
																											fontWeight: '600',
																											left: '0',
																											background: '#121216',
																											height: '100%',
																											width: `${!isReplace ? '100' : resources.upload_percentage}%`,
																											transition: '1s',
																										}}
																									>
																										<span
																											className='body-3'
																											style={{
																												color: '#FFF',
																												fontWeight: '600',
																												position: 'absolute',
																												top: '50%',
																												left: '50%',
																												translate: '-50% -50%'
																											}}>{`${!isReplace ? '100' : resources.upload_percentage}%`}</span>
																									</div>
																								)
																							}
																						</div>
																				}
																			</div>
																			:
																			<div style={{ marginTop: '24px' }} className='w-100 d-flex flex-column align-items-start'>
																				<span className='body-3 black-700' style={{ fontWeight: '500' }}>Insert Link <span className='danger-text'>*</span></span>
																				<Input
																					varrient={'primary'}
																					placeholder="Paste link here"
																					value={welcomeVideo.type === 'url' ? welcomeVideo.src : ''}
																					style={{ marginTop: '12px' }}
																					name="welcome-video"
																					onChange={(e) => {
																						const validate = WelcomeVideoValidator({ type: 'url', src: e.target.value });
																						if (validate.isValidate) {
																							setValidationStatus(validate);
																						}
																						setWelcomeVideo({ type: 'url', src: e.target.value });
																					}}
																					onBlur={() => { updateOnBlur(); }}
																					className={`${props?.errors?.welcome_video?.src
																						? 'primary-input-error'
																						: ''}`}
																				/>
																			</div>

																	}
																</SectionContainer>
																:
																<MinimizeViewOfSection
																	sectionDetails={welcomeVideo}
																	onRemoveSection={() => { }}
																	onSelect={() => { props.getActiveSection(welcomeVideo); }}
																	errors={props.errors}
																/>
														}
													</>
													:
													<EmptySection />
											}
										</div>
									</div>
								</div>
								:
								(openSectionType.type === 'welcome_video' && openSectionType.isOpen) &&
								<div className='create-assessment-secttion-options-container'>
									<div className='section-type-container'>
										{
											_SectionTypes.map((sectionType, index) => {
												const draggable = !isOnTabletScreen && !assessmentSpecsDetails?.sections?.find(section => section?.type === sectionType?.id);
												const type = welcomeVideo?.type === 'id' ? 'file-upload' : 'add-link';
												const btnStatus = (validationStatus && (welcomeVideo.type || welcomeVideo.src)) ? type === sectionType.id ? validationStatus?.isValidate ? { success: true } : { success: false } : null : null;
												return (
													<Fragment key={index}>
														{
															<Button
																onClick={
																	() => {
																		setSelectedSectionType(sectionType.id);
																		setOpenSectionType({ type: null, isOpen: false });
																	}
																}
																draggable={draggable}
																onDragEnd={async () => { addSections(); }}
																onDragStart={() => { setSelectedSectionType(sectionType.id); setFromDragIndex(-1); }}
																className={`draggable 	${btnStatus ? (btnStatus.success === false) ? ' in-section-btn-danger ' : ' in-section-btn-success ' : ''}`}
																key={index} btn='in-section-btn'
																preIcon={btnStatus ? btnStatus?.success ? <TickIcon style={{ width: '24px', height: '24px' }} /> : <WarningIcon style={{ width: '24px', height: '24px' }} /> : sectionType.icon}
																title={sectionType.title}
																style={{ width: '100%', maxWidth: '278px', marginTop: index === 0 ? '0px' : '16px', cursor: `${draggable ? 'grab' : 'not-allowed'}` }} />
														}
													</Fragment>
												);
											})
										}
									</div>
								</div>
						}
					</div>
			}
		</>
	);
}